<template>
  <div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
      groups() {
        if (this.publicData.tour) {
          return this.publicData.tour.groups
        } else {
          return []
        }
      },
      from() {
        if (this.config && this.config.from) {
          return this.config.from
        }
        return null
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          hotspotLink: this.hotspotLink,
        }
      })
    },
    destroyed() {
    },
    methods: {
      hotspotLink(item) {
        console.log(item)
        if (item.type == 'Video') {
          this.getFunc({ target: 'FullPano', name: 'getKrpanoHotspot' })(item.guid).togglepause()
        }
        switch (item.linkType) {
          case 'Pano':
            var query = {}
            //query.senceId = item.linkItemGUID
            if (item.linkFOV) {
              query['view.fov'] = item.linkFOV
            }
            if (item.linkFOV) {
              query['view.hlookat'] = item.linkHLookAt
            }
            if (item.linkFOV) {
              query['view.vlookat'] = item.linkVLookAt
            }
            if (item.linkItem) {
              if (this.config.query) {
                this.$router.push({ query: { panoId: item.linkItem.key, ...query, } })
              } else {
                this.$router.push({ params: { panoId: item.linkItem.key }, query })
              }
              break
            }

            if (item.linkItemGUID) {
              if (item.inheritView) {
                var view = this.getFunc({ target: 'FullPano', name: 'getView' })()
                query['view.fov'] = view.fov
                query['view.hlookat'] = view.hlookat
                query['view.vlookat'] = view.vlookat
                query['view.architectural'] = view.architectural
                query['view.pannini'] = view.pannini
                query['view.distortion'] = view.distortion
              }
              this.getFunc({ target: 'Data', name: 'toPano' })(item.linkItemGUID, query)
              //  this.$router.push({ query: { senceId: item.linkItemGUID, ...query, from: this.$route.query.from, } })
            }
            break
          case 'Tour':
            var query = {}
            //query.senceId = item.linkItemGUID
            if (this.from == 'street') {
              query.from = location.href
            }
            if (item.linkItem) {
              this.$router.push({ name: 'TourView', params: { tourId: item.linkItem.key }, query })
            }
            break
          case 'Inn':
            var query = {}
            if (this.from == 'street') {
              query.from = location.href
            }
            if (item.linkData) {
              this.$router.push({ name: 'Inn-innId-Tour', params: { innId: item.linkData.guid }, query })
            }
            break
          case 'File':
            if (!item.file) {
              break
            }
          case 'Text':
          case 'Link':
            this.getFunc({ target: 'hotspotView', name: 'hotspotShowView' })(item)
            break
          case 'TourGroup':
            var group = this.getGroup(item.linkGroupGUID)
            switch (group.type) {
              case 'Gallery':
              case 'Blog':
                this.getFunc({ target: 'hotspotView', name: 'hotspotShowView' })(item)
                break
              case 'Pano':
                this.getFunc({ target: 'groups', name: 'changeGroupByGuid' })(item.linkGroupGUID)
                break
              default:
            }
            break
          default:
        }
      },
      getGroup(groupGuid) {
        console.log(groupGuid, this.groups)
        for (var i in this.groups) {
          if (this.groups[i].guid == groupGuid) {
            return this.groups[i]
          }
        }
      },

    },
  }
</script>
<style scoped>
  .panoinfo {
    width: 70vw;
    max-width: 600px;
    min-width: 240px;
    color: #fff;
  }

  .exif {
    font-size: 1.2rem
  }

    .exif tr:first-child {
      white-space: nowrap
    }

    .exif td {
      padding: 0 0 0 0;
      border-bottom: none;
    }

    .exif th {
      padding: 0 0 0 0;
      text-align: right;
      width: 6em;
      vertical-align: top;
      border-bottom: none;
    }
</style>
